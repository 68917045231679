.tou>.modal-content {
    height: 500px;
}

.tou>.modal-header{
    padding: 30px 25px 20px 25px ;
}

.tou>.modal-title{
    font-size: 30px;
}


.tou>.modal-body{
    padding: 15px 20px 0 20px;
}
