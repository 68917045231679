.btn-danger {
  background-color: #e41f42 !important;
}

.btn-danger:hover, .btn-danger:active{
  background-color: #a7142e !important;
}

.topbar button{
  background-color: #a7142e !important
}
