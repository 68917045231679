html body {
  margin: 0px;
  padding: 0px;
}

.topbar {
  position: sticky;
  height: 50px;
  background-color: #a7142e;
  color: white;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
}

.topbar button{
  background-color: #a7142e;
  border: 0;
  padding-left: 0;
}

.topbar .col-6{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
}

select {
  border: 1px solid rgb(203, 203, 203);
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='rgb(113, 113, 113)' d='M7.41,8.59L12,13.17l4.59-4.58L18,10l-6,6l-6-6L7.41,8.59z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 30px; /* Adjust the size of the arrow */
}

select:focus {
  outline: none;
  box-shadow: none;
}