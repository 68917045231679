body{
    margin: 20px;
}

.p-3 div{
    font-size: 20px;
}

.login{
    margin: 0;
}

.topbarcontainer{
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.topbar{
    display: flex;
}

.terms{
  cursor: pointer;
}