body{
    margin: 20px;
}

.p-3 div{
    font-size: 20px;
}

.login{
    margin: 0;
}

a.back {
    color: white;
    font-weight: bold;
    text-decoration: none;
    font-size: 1.7em;
}

.topbar{
    display: flex;
}

.appbar-title {
    font-size: 1.7em;
    font-weight: bold;
}

.terms{
  cursor: pointer;
}